@charset "UTF-8";
/* fonts
------------------------------------------------------------ */
@font-face {
  font-family: "optima";
  src: url("/css/fonts/optima/optima-01.eot?#iefix");
  src: url("/css/fonts/optima/optima-01.eot?#iefix") format("eot"), url("/css/fonts/optima/optima-01.woff") format("woff"), url("/css/fonts/optima/optima-01.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "optima";
  src: url("/css/fonts/optima/optima-02.eot?#iefix");
  src: url("/css/fonts/optima/optima-02.eot?#iefix") format("eot"), url("/css/fonts/optima/optima-02.woff") format("woff"), url("/css/fonts/optima/optima-02.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "optima";
  src: url("/css/fonts/optima/optima-03.eot?#iefix");
  src: url("/css/fonts/optima/optima-03.eot?#iefix") format("eot"), url("/css/fonts/optima/optima-03.woff") format("woff"), url("/css/fonts/optima/optima-03.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "optima";
  src: url("/css/fonts/optima/optima-04.eot?#iefix");
  src: url("/css/fonts/optima/optima-04.eot?#iefix") format("eot"), url("/css/fonts/optima/optima-04.woff") format("woff"), url("/css/fonts/optima/optima-04.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "optima";
  src: url("/css/fonts/optima/optima-05.eot?#iefix");
  src: url("/css/fonts/optima/optima-05.eot?#iefix") format("eot"), url("/css/fonts/optima/optima-05.woff") format("woff"), url("/css/fonts/optima/optima-05.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "optima";
  src: url("/css/fonts/optima/optima-06.eot?#iefix");
  src: url("/css/fonts/optima/optima-06.eot?#iefix") format("eot"), url("/css/fonts/optima/optima-06.woff") format("woff"), url("/css/fonts/optima/optima-06.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "optima";
  src: url("/css/fonts/optima/optima-07.eot?#iefix");
  src: url("/css/fonts/optima/optima-07.eot?#iefix") format("eot"), url("/css/fonts/optima/optima-07.woff") format("woff"), url("/css/fonts/optima/optima-07.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "optima";
  src: url("/css/fonts/optima/optima-08.eot?#iefix");
  src: url("/css/fonts/optima/optima-08.eot?#iefix") format("eot"), url("/css/fonts/optima/optima-08.woff") format("woff"), url("/css/fonts/optima/optima-08.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "optima";
  src: url("/css/fonts/optima/optima-09.eot?#iefix");
  src: url("/css/fonts/optima/optima-09.eot?#iefix") format("eot"), url("/css/fonts/optima/optima-09.woff") format("woff"), url("/css/fonts/optima/optima-09.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "optima";
  src: url("/css/fonts/optima/optima-10.eot?#iefix");
  src: url("/css/fonts/optima/optima-10.eot?#iefix") format("eot"), url("/css/fonts/optima/optima-10.woff") format("woff"), url("/css/fonts/optima/optima-10.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "optima";
  src: url("/css/fonts/optima/optima-11.eot?#iefix");
  src: url("/css/fonts/optima/optima-11.eot?#iefix") format("eot"), url("/css/fonts/optima/optima-11.woff") format("woff"), url("/css/fonts/optima/optima-11.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "optima";
  src: url("/css/fonts/optima/optima-12.eot?#iefix");
  src: url("/css/fonts/optima/optima-12.eot?#iefix") format("eot"), url("/css/fonts/optima/optima-12.woff") format("woff"), url("/css/fonts/optima/optima-12.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'app-icons';
  src: url("/css/icons/app-icons.eot");
  src: url("/css/icons/app-icons.eot#iefix") format("embedded-opentype"), url("/css/icons/app-icons.woff") format("woff"), url("/css/icons/app-icons.ttf") format("truetype"), url("/css/icons/app-icons.svg#app-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'app-icons';
    src: url('/css/fonts/icon-fonts/app-icons.svg?13315548#app-icons') format('svg');
  }
}
*/
[class^="ficon-"]:before, [class*=" ficon-"]:before {
  font-family: "app-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  font-size: 120%;
  /* Uncomment for 3D effect */
}

.ficon-menu-2:before {
  content: '\e800';
}

/* '' */
/* mixins
------------------------------------------------------------ */
/* templates
------------------------------------------------------------ */
body {
  background: #fff7f4;
  margin: 0;
}

#app_page {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#app_page i#menu_icon {
  position: absolute;
  top: 1em;
  left: 1em;
  font-size: 2em;
  cursor: pointer;
}

#app_page i#menu_icon:hover {
  color: #5555ff;
}

#app_page > div:first-child {
  text-align: center;
  border: 3px black solid;
  position: relative;
  width: 400px;
  margin: 300px auto;
}

#app_page > div:first-child p, #app_page > div:first-child h1, #app_page > div:first-child h2, #app_page > div:first-child h3, #app_page > div:first-child h4, #app_page > div:first-child h5 {
  text-align: center;
}

#app_page h2 {
  margin: 2em 0;
  text-align: center;
  font: 500 2em optima,arial,tahoma,sans-serif;
  border: 2px blue solid;
}

#app_page h2 span {
  font-weight: 700;
}

#app_menu {
  position: absolute;
  top: 5em;
  left: 0;
  width: 20em;
  bottom: 0;
  background: #444;
  padding: 1em;
}

#app_menu ul {
  padding: 0;
  list-style-type: none;
}

#app_menu li {
  color: #ffffff;
  cursor: pointer;
}

#app_menu li:hover {
  color: #ffff33;
}

/* pages
------------------------------------------------------------ */
#welcome_page {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#welcome_page #welcome_text_area {
  position: absolute;
  margin: 0;
  top: 50%;
  width: 100%;
  text-align: center;
  transform: translateY(-50%);
}

#welcome_page h1 {
  margin: 0;
  font: 500 3em optima,arial,tahoma,sans-serif;
}

#welcome_page h3 {
  margin: 3em 0 0 0;
  font: 500 1.5em optima,arial,tahoma,sans-serif;
}

#product_list_page p#product_list_items span {
  cursor: pointer;
}

#product_list_page p#product_list_items span:hover {
  color: #5555ff;
}
