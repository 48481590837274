

body {
	background: $bgColor;
	margin: 0;
}


#app_page {
	position: absolute;
	overflow: hidden;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	i#menu_icon {
		position: absolute;
		top: 1em;
		left: 1em;
		font-size: 2em;
		cursor: pointer;
	}
	i#menu_icon:hover {
		color: #5555ff;
	}



	> div:first-child {
		text-align: center;
		border: 3px black solid;
		position: relative;
		width: 400px;
		margin: 300px auto;

		p,h1,h2,h3,h4,h5 {
			text-align: center;
		}
	}
	
	
	h2 {
		margin: 2em 0;
		text-align: center;
		font: 500 2em optima,arial,tahoma,sans-serif;
		border: 2px blue solid;

		span {
			font-weight: 700;
		}
	}
}


#app_menu {
	position: absolute;
	top: 5em;
	left: 0;
	width: 20em;
	bottom: 0;
	background: #444;
	padding: 1em;

	ul {
		padding: 0;
		list-style-type: none;
	}
	li {
		color: #ffffff;
		cursor: pointer;
	}
	li:hover {
		color: #ffff33;
	}
}