






#product_list_page {






	p#product_list_items {

		span {
			cursor: pointer;
		}

		span:hover {
			color: #5555ff;
		}
	}


}
